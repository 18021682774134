<template>
    <div class="pageContol listWrap templateList formCom">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">分销管理</a>
                    <i>></i>
                    <a
                        href="javascript:;"
                        class="cur-a"
                    >分销设置</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <div class="form-box">
                            <el-form
                                ref="ruleForm"
                                label-width="8rem"
                                class="form"
                                :rules="rules"
                                :model="ruleForm"
                            >
                                <el-form-item
                                    label="返利百分比："
                                    prop="percentage"
                                >
                                    <el-input
                                        v-model="ruleForm.percentage"
                                        size="small"
                                        oninput="value=value.replace(/[^\d+(,\d\d\d)*.\d+$]/g,'')"
                                    ><template slot="append">%</template></el-input>
                                </el-form-item>
                                <el-form-item
                                    label="提现门槛："
                                    prop="threshold"
                                >
                                    <el-input
                                        v-model="ruleForm.threshold"
                                        size="small"
                                        oninput="value=value.replace(/[^\d+(,\d\d\d)*.\d+$]/g,'')"
                                    ><template slot="append">元</template></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="btn-box flexcc">
                            <el-button
                                class="bgc-bv"
                                @click="doAddSave()"
                                style="width:150px"
                            > 保 存 </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "retailSet",
    data() {
        return {
            // 基本信息
            ruleForm: {
                percentage: "",
                threshold: "",
            },
            rules: {
                percentage: [
                    {
                        required: true,
                        message: "请输入比例",
                        trigger: "blur",
                    },
                ],
                threshold: [
                    {
                        required: true,
                        message: "请输入提现门槛",
                        trigger: "blur",
                    },
                ],
            },
            appContactId: "",
        };
    },
    watch: {},
    created() {
        this.getDetail();
    },
    computed: {},
    methods: {
        //新增
        doAddSave() {
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    this.$post(
                        "/biz/distribute/setting/saveOrUpdate",
                        {
                            percentage: this.ruleForm.percentage,
                            threshold: this.ruleForm.threshold,
                        },
                        3000,
                        true,
                        2
                    ).then((res) => {
                        if (res.status == "0") {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.getDetail();
                        } else {
                            this.$messge.error(res.message);
                        }
                    });
                }
            });
        },
        //编辑回显
        getDetail() {
            this.$post(
                "/biz/distribute/setting/getInfo",
                {},
                3000,
                true,
                2
            ).then((res) => {
                // ;
                if (res.status == "0") {
                    this.ruleForm.percentage = res.data.percentage;
                    this.ruleForm.threshold = res.data.threshold;
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
.form-box {
    margin-bottom: 100px;
}
</style>
